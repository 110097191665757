import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import Image from '~components/Image'
import { useState } from 'react'
import Video from '~components/Video'

const HoverButton = ({ className, project, firstCol, secondCol, thirdCol }) => {
	const [hover, setHover] = useState(false)

	const [mobile, setMobile] = useState(false)

	useEffect(() => {
		if (window.innerWidth < 768) {
			setMobile(true)
		}
	}, [])

	return (
		<ButtonWrap className={className} firstCol={firstCol} secondCol={secondCol} thirdCol={thirdCol}>
			<ProjectLink
				to={resolveLink(project)}	
			>
				<Hover 
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					{project?.title}
				</Hover>
				{!mobile &&
					<HoverBlock hover={hover}>
						{(project?.thumbnailVideo?.asset?.url || project?.thumbnailVimeoVideo) ? 
							<HoverVideo src={project?.thumbnailVimeoVideo ?? project?.thumbnailVideo?.asset?.url}/>
							:
							<HoverImage image={project?.thumbnail}/>
						}
					</HoverBlock>
				}
			</ProjectLink>
		</ButtonWrap>
	)
}

const MoreWork = ({ className, projects }) => {

	const firstThird = (Math.ceil(projects?.length / 3))
	const secondThird = firstThird + firstThird

	return (
		<Wrap className={className}>
			<MoreWorkTitle>More work:</MoreWorkTitle>
			<Columns>
				<DesktopCol>
					{projects?.slice(0, firstThird).map((project, i) => (
						<HoverButton 
							key={project?.title} 
							project={project}
						/>
					))}
				</DesktopCol>
				<DesktopCol>
					{projects?.slice(firstThird, secondThird).map((project, i) => (
						<HoverButton 
							key={project?.title} 
							project={project}
						/>
					))}
				</DesktopCol>
				<DesktopCol>
					{projects?.slice(secondThird, projects?.length).map((project, i) => (
						<HoverButton 
							key={project?.title} 
							project={project}
						/>
					))}
				</DesktopCol>
				<TabletCol>
					{projects?.slice(0, Math.ceil(projects?.length / 2)).map((project, i) => (
						<HoverButton 
							key={project?.title} 
							project={project}
						/>
					))}
				</TabletCol>
				<TabletCol>
					{projects?.slice(Math.ceil(projects?.length / 2), projects?.length).map((project, i) => (
						<HoverButton 
							key={project?.title} 
							project={project}
						/>
					))}
				</TabletCol>
			</Columns>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: 40px 0 64px;
	${mobile}{
		padding: 31px 0 25px;
	}
`
const MoreWorkTitle = styled.div`
	grid-column: span 12;
	margin-bottom: 26px;
`
const Columns = styled.div`
	grid-column: span 12;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	position: relative;
	grid-column-gap: 30px;
	${tablet}{
		grid-template-columns: repeat(2, 1fr);
	}
	${mobile}{
		grid-column-gap: 20px;
	}
`
const ButtonWrap = styled.div`
	grid-column: ${ props => props.firstCol ? '1/2' : props.secondCol ? '2/3' : '3/4'};
`
const ProjectLink = styled(Link)`
	${mobile}{
		margin-bottom: 7px;
		display: block;
	}
`
const Hover = styled.div`
	:hover{
		text-decoration: underline;
	}
`
const HoverBlock = styled.div`
	position: absolute;
	width: 19%;
	top: 0;
	transform: translateX(75%);
	pointer-events: none;
	opacity: ${ props => props.hover ? '1' : '0'};
	z-index: 1;
	${tablet}{
		width: 30%;
		transform: translateX(66.5%);
	}
	${mobile}{
		display: none;
	}
`
const HoverImage = styled(Image)`

`
const HoverVideo = styled(Video)`
	max-width: 100%;
	display: block;
`

const DesktopCol = styled.div`
	${tablet}{
		display: none;
	}
`
const TabletCol = styled.div`
	display: none;
	${tablet}{
		display: block;
	}
`
MoreWork.propTypes = {
	className: PropTypes.string,
	projects: PropTypes.array,
}

HoverButton.propTypes = {
	className: PropTypes.string,
	project: PropTypes.object,
}

export default MoreWork