import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import VideoPlayer from '~components/VideoPlayer'
import Media from '~components/Media'

const SingleMedia = ({ className, content }) => {
	return (
		<Wrap 
			className={className} 
			bg={content?.background} 
			padding={content?.padding}
		>
			{content?.showVideoControls ? 
				<VideoPlayer 
					desktopVideo={content?.media?.desktopVideo?.asset?.url}
					mobileVideo={content?.media?.mobileVideo?.asset?.url}
					soundToggle={content?.media?.showMuteToggle}
				/>
				:
				<Media media={content.media}/>
			}
		</Wrap>
	)
}

const Wrap = styled.div`
	background-color: ${ props => props.bg};
	padding: ${ props => props.padding ? '115px  71px' : '0'};
	transform: scale(1.01);
	${mobile}{
		padding: ${ props => props.padding ? '125px  20px' : '0'};
	}
`

SingleMedia.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default SingleMedia