import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import RichText from '~components/RichText'

const TextModule = ({ className, content }) => {
	return (
		<Wrap className={className} bg={content?.background} padding={content?.background !== '#FFFFFF'}>
			<Main>
				<RichText content={content?.text}/>
			</Main>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	margin-top: ${ props => props.padding ? '0' : '115px'};
	margin-bottom: ${ props => props.padding ? '0' : '118px'};
	padding-top: ${ props => props.padding ? '115px' : '0'};
	padding-bottom: ${ props => props.padding ? '118px' : '0'};
	background-color: ${ props => props.bg ? props.bg : 'var(--white)'}; 
	${mobile}{
		margin-top: ${ props => props.padding ? '0' : '39px'};
		margin-bottom: ${ props => props.padding ? '0' : '40px'};
		padding-top: ${ props => props.padding ? '39px' : '0'};
		padding-bottom: ${ props => props.padding ? '40px' : '0'};
	}
`
const Main = styled.div`
	grid-column: 3/9;
	${mobile}{
		grid-column: span 12;
	}
`

TextModule.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default TextModule