import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SingleMedia from '~components/SingleMedia'
import TextModule from '~components/TextModule'
import DoubleMedia from '~components/DoubleMedia'

const RenderModules = ({ className, modules }) => {
	return (
		<div className={className}>
			{modules?.map(module => (
				<RenderModule  key={module._key} module={module} />
			))}
		</div>
	)
}

const RenderModule = ({ module }) => {
	switch (module._type) {
	case 'singleMedia':
		return <SingleMedia content={module} />
	case 'textModule':
		return <TextModule content={module} />
	case 'doubleMedia':
		return <DoubleMedia content={module} />
	default:
		return null
	}
}

RenderModules.propTypes = {
	className: PropTypes.string,
	modules: PropTypes.array,
}

RenderModule.propTypes = {
	module: PropTypes.object,
}

export default RenderModules